.supplierHeader {
    
    position: fixed;
    flex: auto;
    background: #fff;
    height: 100px;
    padding: 1.5rem 0 1.5rem 0;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
    z-index: 90;
}

.supplierHeader .left {

    width: 250px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: transparent;
    transition: all .2s ease;
}

.supplierHeader .headers {
    display: flex;
    width: 100%;
    align-items: center;
}

.supplierHeader .left a {
    width: 100%;
    text-align: center;


}

.supplierHeader a {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #888;
    font-size: 13px;
}

.supplierHeader .anticon {
    margin-right: 5px;
    margin-left: 5px;
}

.bg-primary {
    background-color: #410099 !important;
}


.justify-content {
    justify-content: space-around;
}

.supplierHeader .left img {

    height: 60px;
}
.supplierHeader .middle {
    width: 250px;
    
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 7px 7px 0 !important;
    background-color: #410099 !important;
    border-color: #fff !important;
}



.supplierHeader .right {
    display: flex;
    width: calc(100% - 500px);
    justify-content: flex-end;
    padding-right: 50px;
}


.supplierHeader .right ul{
    display: flex;
    list-style: none;
    align-items: center;
}
.supplierHeader .right ul li{
    margin-right: 15px;
}

.mains .middle .card {
    margin-bottom: 15px;
}

.mains .middle .card .card-body .item {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
}

.mains .middle .card .card-body {
    padding: 20px;
}


.mains .middle .card .card-body .item .deg {
    margin-left: 30px;
    font-weight: 600;
}

.mains .middle .card .card-body .compare {
    font-size: 16px;
    color: #72849a !important
}

.mains .middle .card .card-body .lastweektitle {
    font-size: 12px;
    color: #72849a !important
}

.mains .middle .card .card-body .item .deg {
    margin-left: 30px;
    font-weight: 600;
}

.mains .middle .card .card-body .titleSub {
    font-size: 0.8rem;
    color: #72849a !important;
}

.mains .middle .card .card-body .reveneuPriceParent {
    align-items: center;
    padding-bottom: 18px;
}

.mains .middle .card .card-body .reveneuPriceParent .reveneuPrice{
    align-items: center;
}

.mains .middle .card .card-body .reveneuPriceParent .reveneuLastWeek {
    display: flex;
    align-items: center;
}
.mains .middle .card .card-body .reveneuPriceParent .reveneuLastWeek .glyph-icon  {
    margin-left: 10px;
    margin-right: 10px;
    
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td  {
    padding: 9px 16px !important;
}

.mt-15 {
    margin-top: 15px;
}

.mains .middle .card .card-body .icons {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 22px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.mains .middle .card .card-body .icons.cyan {
    background: rgba(4,209,130,.1);
    color: #04d182;
    
}

.mains .middle .card .card-body .icons.blue {
    background: rgba(62,121,247,.1);
    color: #3e79f7;
}

.mains .middle .card .card-body .icons.volcano {
    background: rgba(255,107,114,.1);
    color: #ff6b72;
}

.mains .middle .card .card-body .icons.gold {
    background: rgba(255,197,66,.1);
    color: #ffc542;
}
.mains .middle .card .card-body h3 {
    width: 100%;
}

.tableTitle {
    margin-bottom: 30px;
}